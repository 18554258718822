import React from 'react'
import { graphql } from 'gatsby'

import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'
import useMediaQuery from '@mui/material/useMediaQuery'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Seo from '../../components/seo'
import Layout from '../../components/layout'

import KlaviyoForm from '../../components/utilities/klaviyo-form'

const EnterPage = (props) => {
  const influencer = props.data.influencersYaml

  const featureBlocks = props.data.allEnterBlocksYaml.nodes.map((node, index) => (
    <div className="d-flex align-items-center icon-block-row" key={`icon-block-${index}`}>
      <div className="flex-shrink-0">
        <GatsbyImage
          image={getImage(node.block.icon)}
          alt={`${node.block.title} graphic`}
          className="mb-1"
        />
      </div>
      <div className="flex-grow-1 ms-1">
        <h3>{node.block.title}</h3>
        <p className="mb-0">{node.block.description}</p>
      </div>
    </div>
  ))

  const isMobile = useMediaQuery('(max-width:767px)', { noSsr: true })

  let hostname = ''
  const isBrowser = () => typeof window !== 'undefined'
  if (isBrowser()) {
    hostname = window.location.hostname
  }
  const isLive = hostname.includes('meati.com')
  const formCode = isLive ? 'TgVycs' : 'UuM6X8'

  return (

    <Layout>
      <Seo title="Enter" noindex />

      <header id="hero" className="py-3 py-lg-4 py-xl-5 bg-light">
        <Container>
          <Row className="align-items-center gx-lg-4">
            <Col md="6" lg="7">
              <h1 className="h2 mb-1 mb-md-2">
                A special opportunity for friends of
                {' '}
                {influencer.person}
              </h1>
              <p className="lead mb-md-2">
                Meati™ is a new whole food made from nutrient-dense mushroom root delivering clean, healthy nourishment and mind-blowing flavor.
              </p>
              { !isMobile && featureBlocks }
            </Col>
            <Col md="6" lg="5">
              <Card id="referralForm" className="p-1 mb-2 mb-md-0">
                <StaticImage
                  src="../../images/meati-crispy-cutlet.jpg"
                  width={468.67}
                  quality={95}
                  formats={['AUTO', 'WEBP']}
                  alt="Meati™ Boulder map"
                  placeholder="blurred"
                  sizes="(min-width: 376px) 403px, (min-width: 428px) 551px, (min-width: 576px) 307.58px, (min-width: 768px) 400.91px, (min-width: 992) 587.5px, (min-width: 1200) 687.5px, (min-width: 1440) 660px, 375px"
                  layout="constrained"
                  className="mb-1 rounded-top"
                />
                <h2 className="fs-2">Enter to Win</h2>
                <p className="lead">Enter your email address for a chance to win a Meati™ bundle! If chosen in a random drawing, Meati™ will be in touch to get your shipping info. You’ll be one of the first in the world to try Meati™!</p>
                <KlaviyoForm code={formCode} />
              </Card>
              { isMobile && featureBlocks }
            </Col>
          </Row>
        </Container>
      </header>
    </Layout>
  )
}

export default EnterPage

export const query = graphql`
  query ($id: String!) {
    influencersYaml(id: { eq: $id }) {
      firstname
      person
    }
    allEnterBlocksYaml {
      nodes {
        block {
          title
          description
          icon {
            childImageSharp {
              gatsbyImageData(width: 100, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  }
`
